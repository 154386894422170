<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-2">
            <div class="row">
                <div class="col-md-12">
                    <h5>{{ $t("productPackaging.packageInfo") }}</h5>
                    <div class="tw-border tw-border-gray-200 tw-p-3 tw-rounded">
                        <div class="tw-grid tw-grid-cols-2 tw-gap-3">
                            <div>
                                {{ $t("productPackaging.shopName") + ": " }}
                                <b>{{ record.shop_name }}</b>
                            </div>
                            <div>
                                {{
                                    $t("productPackaging.packagingNumber") +
                                        ": "
                                }}
                                <b>{{ record.packaging_number }}</b>
                            </div>
                            <div>
                                {{
                                    $t("productPackaging.receiverName") + ": "
                                }}
                                <b>{{ record.receiver_name }}</b>
                            </div>
                        </div>
                        <div class="tw-overflow-hidden tw-mt-3 tw-rounded" style="max-height:250px">
                            <table class="tw-w-full">
                                <thead>
                                    <tr>
                                        <th class="tw-uppercase tw-bg-gray-200 tw-px-2">{{ $t('no') }}</th>
                                        <th class="tw-uppercase tw-bg-gray-200 tw-px-2">{{ $t('productPackaging.productName') }}</th>
                                        <th class="tw-uppercase tw-bg-gray-200 tw-px-2">{{ $t('productPackaging.uom') }}</th>
                                        <th class="tw-uppercase tw-bg-gray-200 tw-px-2 tw-text-center">{{ $t('productPackaging.qty') }}</th>
                                    </tr>
                                </thead>
																<tbody>
																	<tr
																		v-for="(d, index) in record.details"
																		:key="index"
																	>
																		<td class="tw-px-2 tw-text-center tw-w-8">{{ index + 1 }}</td>
																		<td class="tw-px-2">{{ d.product_name_en }}</td>
																		<td class="tw-px-2">{{ d.uom_name_en }}</td>
																		<td class="tw-px-2 tw-text-center">{{ d.quantity }}</td>
																	</tr>
																</tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label>{{ $t("productPackaging.comment") }}</label>
                    <textarea
                        v-model="model.comment"
                        :placeholder="$t('productPackaging.comment')"
                        rows="3"
                        class="form-control"
                    ></textarea>
                    <div class="validate" v-if="errors.has('comment')">
                        {{ errors.first("comment") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        class="mr-2"
                        @click.prevent="() => $emit('cancel')"
                    >
                        {{ $t("cancel") }}
                    </ts-button>
                    <ts-button color="primary" @click.prevent="onSave">{{
                        $t("save")
                    }}</ts-button>
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { Errors } from "form-backend-validation";

export default {
    name: "confirmModal",
    props: ["record"],
    data() {
        return {
            loading: false,
            errors: new Errors(),
            model: {
                comment: ""
            }
        };
    },
    methods: {
        onSave() {
            this.errors = new Errors();
            this.loading = true;
            this.$store
                .dispatch("inventory/productPackaging/confirm", {
                    id: this.record.packaging_id,
                    data: this.model
                })
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
