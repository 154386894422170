<template>
    <div>
        <ts-page-title
            :title="$t('productPackaging.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('productPackaging.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <div class="tw-space-x-3">
                        <a-button
                            v-if="$can('create-product-packaging')"
                            type="primary"
                            @click="addNew"
                        >
                            {{ $t("addNew") }}
                        </a-button>
                        <a-range-picker
                            v-model="dataRange"
                            format="DD-MM-YYYY"
                            valueFormat="DD-MM-YYYY"
                            class="tw-w-52"
                            @change="fetchData()"
                        />
                    </div>
                    <div class="tw-space-x-3">
                        <a-radio-group
                            v-model="isJdConfirmed"
                            @change="fetchData()"
                        >
                            <a-radio-button :value="0">
                                {{ $t("productPackaging.confirm") }}
                            </a-radio-button>
                            <a-radio-button :value="1">
                                {{ $t("productPackaging.confirmed") }}
                            </a-radio-button>
                        </a-radio-group>
                        <a-tooltip :title="$t('productPackaging.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('productPackaging.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-x-scroll">
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                >
                    <template v-slot="{ record }">
                        <td>{{ record.packaging_date }}</td>
                        <td>{{ record.packaging_number }}</td>
                        <td>{{ record.shop_name }}</td>
                        <td>{{ record.receiver_name }}</td>
                        <td>{{ record.receiver_phone }}</td>
                        <td>{{ record.receiver_location }}</td>
                        <td>{{ record.remarks }}</td>
                        <td>
                            <span
                                class="badge bg-primary"
                                v-if="record.booking_count"
                                >Yes</span
                            >
                            <span class="badge bg-danger" v-else>No</span>
                        </td>
                        <td class="text-left tw-space-x-3" style="width:100px">
                            <template v-if="!record.is_deleted">
                                <a-tooltip
                                    :title="$t('productPackaging.confirm')"
                                    v-if="$whois.admin()"
                                >
                                    <a
                                        href="#"
                                        @click.prevent="
                                            openModalConfirm(record)
                                        "
                                        ><i class="fa fa-check"></i
                                    ></a>
                                </a-tooltip>
                                <a
                                    v-if="
                                        $can('update-product-packaging') &&
                                            !record.is_jd_confirmed
                                    "
                                    href="#"
                                    v-tooltip="$t('edit')"
                                    class="text-primary mr-2"
                                    @click.prevent="edit(record)"
                                >
                                    <i class="fas fa-edit"></i>
                                </a>
                                <a-popconfirm
                                    v-if="
                                        $can('delete-product-packaging') &&
                                            !record.is_jd_confirmed
                                    "
                                    placement="leftTop"
                                    :title="$t('are_you_sure_to_delete')"
                                    :ok-text="$t('yes')"
                                    :cancel-text="$t('no')"
                                    @confirm="deleteRecord(record)"
                                >
                                    <a href="#" :disabled="record._deleting">
                                        <i
                                            class="fas fa-circle-notch spin"
                                            v-if="record._deleting"
                                        ></i>
                                        <i
                                            class="fa fa-trash text-red"
                                            v-else
                                        ></i>
                                    </a>
                                </a-popconfirm>
                                <a-tooltip
                                    :title="$t('productPackaging.booking')"
                                    v-if="!record.booking_count"
                                >
                                    <a
                                        href="#"
                                        @click.prevent="
                                            openConvertBooking(record)
                                        "
                                        ><i class="fas fa-calendar-check"></i
                                    ></a>
                                </a-tooltip>
                            </template>
                        </td>
                    </template>
                </ts-table>
            </div>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>

        <!-- form-action -->
        <a-modal
            v-if="showForm"
            v-model="showForm"
            :title="$t('productPackaging.pageTitle')"
            centered
            :maskClosable="false"
            @cancel="clearEdit"
            :zIndex="1020"
            :footer="null"
            width="50%"
        >
            <FormAction @cancel="clearEdit" />
        </a-modal>

        <a-modal
            v-if="isConfirmed"
            v-model="isConfirmed"
            :title="$t('productPackaging.confirm')"
            centered
            :maskClosable="false"
            @cancel="closeModalConfirm"
            :zIndex="1020"
            :footer="null"
        >
            <ModalConfirm
                :record="packagingRecord"
                @cancel="closeModalConfirm"
            />
        </a-modal>

        <a-modal
            v-if="convertToBooking"
            v-model="convertToBooking"
            :title="$t('bookingPickup.pageTitle')"
            centered
            :maskClosable="false"
            @cancel="closeFormConvertBooking"
            :zIndex="1020"
            :footer="null"
        >
            <CovertBooking
                :packaging="packagingData"
                @cancel="closeFormConvertBooking"
            />
        </a-modal>
    </div>
</template>
<script>
import { debounce } from "lodash";
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import FormAction from "./form";
import Reason from "./reason-delete.vue";
import ModalConfirm from "./confirm-modal.vue";
import CovertBooking from "./convert-booking.vue";

export default {
    name: "productPackaging",
    components: {
        FormAction,
        CovertBooking,
        ModalConfirm
    },
    data() {
        return {
            loading: false,
            showForm: false,
            isConfirmed: false,
            convertToBooking: false,
            packagingData: [],
            packagingRecord: null,
            errors: new Errors(),
            model: {
                deleted_reason: ""
            }
        };
    },
    computed: {
        ...mapState("inventory/productPackaging", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.inventory.productPackaging.search;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/productPackaging/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "inventory/productPackaging/RESET_CURRENT_PAGE"
                );
            }
        },
        isJdConfirmed: {
            get() {
                return this.$store.state.inventory.productPackaging
                    .isJdConfirmed;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/productPackaging/SET_CONFIRMED",
                    newValue
                );
            }
        },
        dataRange: {
            get() {
                return this.$store.state.inventory.productPackaging.date_range;
            },
            set(newValue) {
                this.$store.commit(
                    "inventory/productPackaging/SET_DATE_RANGE",
                    newValue
                );
            }
        },
        columns() {
            return [
                { name: this.$t("productPackaging.packagingDate") },
                { name: this.$t("productPackaging.packagingNumber") },
                { name: this.$t("productPackaging.shopName") },
                { name: this.$t("productPackaging.receiverName") },
                { name: this.$t("productPackaging.phoneNumber") },
                { name: this.$t("productPackaging.receiverLocation") },
                { name: this.$t("productPackaging.remark") },
                { name: this.$t("productPackaging.booking") },
                {
                    name: this.$t("actions")
                }
            ];
        }
    },
    methods: {
        ...mapActions("inventory/productPackaging", ["destroy"]),
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("inventory/productPackaging/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        addNew() {
            this.showForm = true;
        },
        clearEdit() {
            this.$store.commit("inventory/productPackaging/SET_EDIT_DATA", {});
            this.fetchData();
            this.showForm = false;
        },
        openModalConfirm(item) {
            this.isConfirmed = true;
            this.packagingRecord = item;
        },
        openConvertBooking(item) {
            this.convertToBooking = true;
            this.packagingData = item;
        },
        closeModalConfirm() {
            this.isConfirmed = false;
            this.fetchData();
        },
        closeFormConvertBooking() {
            this.convertToBooking = false;
            this.fetchData();
        },
        edit(record) {
            this.$store.commit(
                "inventory/productPackaging/SET_EDIT_DATA",
                record
            );
            this.showForm = true;
        },
        deleteRecord(record) {
            this.model.deleted_reason = null;
            this.$confirm({
                title: this.$t("are_you_sure_to_delete"),
                content: h =>
                    h(Reason, {
                        props: {
                            value: this.model,
                            validate: this.errors,
                            placeholder: this.$t("productPackaging.leaveReason")
                        }
                    }),
                okText: this.$t("yes"),
                okType: "danger",
                cancelText: this.$t("no"),
                onOk: () => {
                    return this.destroy({
                        id: record.packaging_id,
                        data: this.model
                    })
                        .then(response => {
                            this.$notify({
                                type: "success",
                                text: response.message
                            });
                            this.fetchData();
                            return Promise.resolve(response);
                        })
                        .catch(error => {
                            this.$notify({
                                type: "error",
                                text: error.message
                            });
                            this.errors = new Errors(error.errors);
                            return Promise.reject(error);
                        });
                },
                onCancel() {}
            });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("inventory/productPackaging/RESET_STATE");
        next();
    }
};
</script>
