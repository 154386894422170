<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-2">
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("productPackaging.shopName")
                    }}</label>
                    <a-select
                        v-model="model.shop_id"
                        :options="shopList"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        class="tw-w-full"
                        :placeholder="$t('productPackaging.shopName')"
												:disabled="(!$whois.admin() && !$whois.agency())"
                        @change="onShopChange"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('shop_id')"
                    >
                        {{ errors.first("shop_id") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("productPackaging.receiverLocation")
                    }}</label>
                    <input
                        v-model="model.receiver_location"
                        type="text"
                        class="form-control"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('receiver_location')"
                    >
                        {{ errors.first("receiver_location") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("productPackaging.receiverName")
                    }}</label>
                    <input
                        v-model.trim="model.receiver_name"
                        type="text"
                        class="form-control"
                        :placeholder="$t('productPackaging.receiverName')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('receiver_name')"
                    >
                        {{ errors.first("receiver_name") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("productPackaging.phoneNumber")
                    }}</label>
                    <input
                        v-model.trim="model.receiver_phone"
                        type="text"
                        class="form-control"
                        :placeholder="$t('productPackaging.phoneNumber')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('receiver_phone')"
                    >
                        {{ errors.first("receiver_phone") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("productPackaging.purchaseAmount")
                    }}</label>
                    <input
                        v-model.number="model.purchase_amount"
                        type="number"
                        class="form-control"
                        :placeholder="$t('productPackaging.purchaseAmount')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('purchase_amount')"
                    >
                        {{ errors.first("purchase_amount") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h2 class="tw-uppercase">
                        <small>{{ $t("productPackaging.package") }}</small>
                    </h2>
                    <div
                        class="tw-rounded-lg tw-overflow-hidden tw-shadow"
                        style="min-height:200px; max-height:300px"
                    >
                        <div
                            class="tw-overflow-y-scroll"
                            style="max-height:250px"
                        >
                            <table class="tw-w-full">
                                <thead>
                                    <tr>
                                        <th
                                            class="tw-bg-gray-100 tw-sticky tw-top-0 tw-z-10 tw-py-1 tw-px-2 tw-uppercase"
                                        >
                                            {{
                                                $t(
                                                    "productPackaging.productName"
                                                )
                                            }}
                                        </th>
                                        <th
                                            class="tw-bg-gray-100 tw-sticky tw-top-0 tw-z-10 tw-py-1 tw-px-2 tw-w-40"
                                        >
                                            {{ $t("productPackaging.uom") }}
                                        </th>
                                        <th
                                            class="tw-bg-gray-100 tw-sticky tw-top-0 tw-z-10 tw-py-1 tw-px-2 tw-w-32"
                                        >
                                            {{ $t("productPackaging.qty") }}
                                        </th>
                                        <th
                                            class="tw-w-8 tw-bg-gray-100 tw-sticky tw-top-0 tw-z-10 tw-py-1 tw-text-center"
                                        >
                                            <a href="#" @click.prevent="addRow"
                                                ><i class="fa fa-plus"></i
                                            ></a>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        class="hover:tw-bg-gray-100"
                                        v-for="(item,
                                        index) in model.product_detail"
                                        :key="index"
                                    >
                                        <td class="tw-py-1 tw-px-2">
                                            <a-select
                                                v-model="item.product_id"
                                                class="tw-w-full"
                                                :options="productList"
                                            ></a-select>
                                            <div
                                                class="tw-text-red-600 tw-text-xs"
                                                v-if="
                                                    errors.has(
                                                        'product_detail.' +
                                                            index +
                                                            '.product_id'
                                                    )
                                                "
                                            >
                                                {{
                                                    errors.first(
                                                        "product_detail." +
                                                            index +
                                                            ".product_id"
                                                    )
                                                }}
                                            </div>
                                        </td>
                                        <td class="tw-py-1 tw-px-2">
                                            <a-select
                                                v-model="item.uom_id"
                                                class="tw-w-full"
                                                :options="
                                                    uomList(item.product_id)
                                                "
                                            ></a-select>
                                            <div
                                                class="tw-text-red-600 tw-text-xs"
                                                v-if="
                                                    errors.has(
                                                        'product_detail.' +
                                                            index +
                                                            '.uom_id'
                                                    )
                                                "
                                            >
                                                {{
                                                    errors.first(
                                                        "product_detail." +
                                                            index +
                                                            ".uom_id"
                                                    )
                                                }}
                                            </div>
                                        </td>
                                        <td
                                            class="tw-py-1 tw-px-2"
                                            valign="top"
                                        >
                                            <input
                                                v-model.number="item.qty"
                                                type="number"
                                                class="form-control"
                                            />
                                            <div
                                                class="tw-text-red-600 tw-text-xs"
                                                v-if="
                                                    errors.has(
                                                        'product_detail.' +
                                                            index +
                                                            '.qty'
                                                    )
                                                "
                                            >
                                                {{
                                                    errors.first(
                                                        "product_detail." +
                                                            index +
                                                            ".qty"
                                                    )
                                                }}
                                            </div>
                                        </td>
                                        <td
                                            class="tw-py-1 tw-px-2 tw-text-center"
                                            valign="middle"
                                        >
                                            <a
                                                href="#"
                                                class="tw-text-red-700"
                                                @click.prevent="
                                                    removeRow(index)
                                                "
                                                ><i class="fa fa-trash"></i
                                            ></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <textarea
                        v-model="model.remarks"
                        rows="2"
                        class="form-control"
                        :placeholder="$t('productPackaging.remark')"
                    ></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        class="mr-2"
                        @click.prevent="() => $emit('cancel')"
                    >
                        {{ $t("cancel") }}
                    </ts-button>
                    <ts-button
                        v-if="!isUpdate"
                        outline
                        color="info"
                        @click.prevent="onSaveAddNew"
                        >{{ $t("saveAddNew") }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        @click.prevent="onSave"
                        >{{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        >{{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import { isEmpty, uniqBy } from "lodash";

export default {
    name: "formAction",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            model: {
                packaging_id: 0,
                shop_id: undefined,
                receiver_name: "",
                receiver_phone: "",
                receiver_location: "",
                remarks: "",
                product_detail: []
            }
        };
    },
    computed: {
        ...mapState("inventory/productPackaging", ["formModels", "edit_data"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        shopList() {
            if (!this.formModels.shop) return [];

            return this.formModels.shop.map(el => ({
                value: el.shop_id,
                label: el.shop_name
            }));
        },
        uomList: vm => productId => {
            if (productId != undefined || productId != "") {
                let product = vm.formModels.product.filter(
                    p => p.product_id == productId
                );
                return uniqBy(product, "uom_id").map(el => ({
                    value: el.uom_id,
                    label: el.uom_name_en
                }));
            } else {
                return [];
            }
        },
        productList() {
            if (!this.formModels.product) return [];

            return uniqBy(this.formModels.product, "product_id").map(el => ({
                value: el.product_id,
                label: el.product_name_en
            }));
        }
    },
    created() {
        this.initialData();
    },
    methods: {
        ...mapActions("inventory/productPackaging", [
            "getFormViewData",
            "store",
            "update"
        ]),
        addRow() {
            this.model.product_detail.push({
                product_id: "",
                uom_id: "",
                qty: 1
            });
        },
        removeRow(index) {
            this.model.product_detail.splice(index, 1);
        },
        fetchFormView({ params, index }) {
            this.loading = true;
            this.getFormViewData({ params: params, index: index })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onUpdate() {
            this.errors = new Errors();
            this.loading = true;
            this.update({
                id: this.model.packaging_id,
                data: this.model
            })
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearInput() {
            this.model.packaging_id = "";
            this.model.shop_id = undefined;
            this.model.receiver_name = "";
            this.model.receiver_phone = "";
            this.model.receiver_location = "";
            this.model.remarks = "";
            this.model.purchase_amount = "";
            this.model.product_detail = [];
        },
        async onShopChange(value) {
            this.model.product_detail = [
                {
                    product_id: "",
                    uom_id: "",
                    qty: 1
                }
            ];
            this.fetchFormView({
                index: "product",
                params: {
                    shopId: value,
                    fnName: "product"
                }
            });
        },
        async initialData() {
            if (!isEmpty(this.edit_data)) {
                this.loading = true;
                await this.getFormViewData({
                    index: undefined,
                    params: {
                        shopId: this.edit_data.shop_id
                    }
                })
                    .then(() => {
                        this.model.packaging_id = this.edit_data.packaging_id;
                        this.model.receiver_name = this.edit_data.receiver_name;
                        this.model.receiver_phone = this.edit_data.receiver_phone;
                        this.model.receiver_location = this.edit_data.receiver_location;
                        this.model.remarks = this.edit_data.remarks;
                        this.model.purchase_amount = this.edit_data.purchase_amount;
                        this.model.shop_id = this.edit_data.shop_id;
                        this.model.product_detail = this.edit_data.details.map(
                            el => ({
                                product_id: el.product_id,
                                uom_id: el.uom_id,
                                qty: el.quantity
                            })
                        );
                    })
                    .finally(() => (this.loading = false));
            } else {
                this.fetchFormView({ params: {}, index: undefined });
            }
        }
    }
};
</script>

<style lang="css" scope>
/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
